import debounce from "debounce";
import * as React from "react";

// https://stackoverflow.com/a/37511463
export function removeDiacritics(string: string) {
  return string.normalize("NFKD").replace(/[\u0300-\u036f]/g, "");
}

export function matchText(text: string, match: string, options?: { exact?: boolean }) {
  const haystack = removeDiacritics(text.toLowerCase());
  const needle = removeDiacritics(match.toLowerCase());

  return options?.exact === true ? haystack === needle : haystack.includes(needle);
}

type AnyFn = (...args: readonly any[]) => unknown;

export function useStableFn<T extends AnyFn>(fn: T) {
  const fnRef = React.useRef(fn);
  fnRef.current = fn;

  return React.useCallback((...args: any[]) => {
    return fnRef.current(...args);
  }, []) as T;
}

export function useDebouncedFn<T extends AnyFn>(stableFn: T, delay: number) {
  const lastDelayRef = React.useRef(delay);
  const [debouncedFn, setDebouncedFn] = React.useState(() => debounce(stableFn, delay));

  if (delay !== lastDelayRef.current) {
    lastDelayRef.current = delay;
    debouncedFn.flush();
    setDebouncedFn(debounce(stableFn, delay));
  }

  return debouncedFn;
}

export function useStateWithDebouncedSetter<T>(state: T, setState: (state: T) => void, delay: number) {
  const lastStateRef = React.useRef(state);
  const [localState, setLocalState] = React.useState(state);

  const stableSetState = useStableFn(setState);
  const debouncedSetState = useDebouncedFn(stableSetState, delay);
  const debouncedSetStateRef = React.useRef(debouncedSetState);
  debouncedSetStateRef.current = debouncedSetState;

  React.useEffect(() => {
    if (state !== lastStateRef.current) {
      lastStateRef.current = state;
      debouncedSetStateRef.current.clear();
      setLocalState(state);
    }
  }, [state, stableSetState]);

  const enhancedSetState = React.useCallback(
    (state: T) => {
      setLocalState(state);
      debouncedSetState(state);
    },
    [debouncedSetState],
  );

  return [localState, enhancedSetState] as const;
}

export const useIsomorphicLayoutEffect = typeof document !== "undefined" ? React.useLayoutEffect : React.useEffect;

export type RequireSome<T, K extends keyof T> = Omit<T, K> & NonNullableValues<Required<Pick<T, K>>>;

export type NonNullableValues<T> = { [K in keyof T]: NonNullable<T[K]> };
